import React from "react";
import img1 from '../../assets/img/about_frame.png'


class Conditions extends React.Component {
    render() {
        return (
            <div className="container p-5">
                <div className="row align-items-center">
                    <div className="about-main col-lg-12 py-3 wow fadeInUp text-center" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        <span className="subhead"></span>
                        
                        <h2 className="about-header title-section fw-bold mb-3" >TERMS & CONDITIONS </h2>
                        {/* <div className="divider"></div> */}

                        <p className="about-detail">QAPL hereby agrees to supply High speed Internet access and services to the Customer on the terms & conditions mentioned herein.</p>
                        <p className="about-detail">The commissioning of services is subject to technical feasibility of the connection and realization of payment. QAPL will not be responsible for any direct, consequential or other loss incurred or suffered by the subscriber due to delay in installation or commissioning of service. </p>
                        <h5 className="about-mini fw-bold mt-5">SERIVCE </h5>
                        <p className="about-detail">QAPL Provide its services via Optical Fiber or wireless, which requires us to install and power a Router / Modem at my premises. I accept this requirement and hereby accord the permission for installing this equipment and give power for the same, so that QAPL’s internet services may be installed and commissioned.</p>
                        <h5 className="about-mini fw-bold mt-5">BILLING </h5>
                        <p className="about-detail">New QAPL subscribers are billed on 1st of the month subsequent to the Month in which they have subscribed to QAPL services</p>
                        <p className="about-detail">QAPL does the billing for its Monthly packages one month in advance. All subscribers of QAPL’s services are by default aligned to the 1st to 1st Monthly billing cycle.</p>
                        <p className="about-detail">QAPL does the billing for its fixed term packages (like Annual, Semi Annual etc packages) from the date on which package is activated till the date the package expires</p>
                        <p className="about-detail">QAPL invoice becomes due a payable, immediately upon the bill being raised</p>
                        <h5 className="about-mini fw-bold mt-5">PAYMENT</h5>
                        <p className="about-detail">At the time of subscribing to QAPL’s data services, the subscriber is required to pay the following charges.</p>
                        <p className="about-detail">Installation charges, if required as per the terms of the package being subscribed to</p>
                        <p className="about-detail">Security Deposit, if required as per the terms of the package being subscribed to.</p>
                        <p className="about-detail">Any other charges as may be required as informed by QAPL sales staff to the subscriber.</p>
                        <p className="about-detail">QAPL subscribers are required to pay their bills immediately on receipt of the same, else the services may be disconnected without notice.</p>
                        <p className="about-detail">Billing disputes will only be entertained upon the subscriber first cleaning the entire amount billed by QAPL. If QAPL finds any genuine errors in its billing, the same will be passed to the subscriber by the way of a credit note in subsequent months.</p>
                        <p className="about-detail">QAPL provides multiple monthly services to its subscribers, and its subscribers and not allowed to hold back or delay payments of one service on account of any issues or concerns that subscriber may have with another service in such circumstances. QAPL retains the right to disconnect the services of the subscriber without notice, in case of non-payment within prescribed time.</p>
                        <p className="about-detail">QAPL assigns credit limits to its subscribers based on certain parameters, and in case the subscribers cross these limits, they will be disconnected from the QAPL service with or without notice.</p>
                        <h5 className="about-mini fw-bold mt-5">SHIFTING CONNECTIVITY </h5>
                        <p className="about-detail">QAPL allows its customers to request for a connection transfer in case you are shifting your residence/office. The same is a chargeable service and it subject to network feasibility</p>
                    </div>
                    {/* <div className="col-lg-6 py-3 wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                        <div className="img-fluid py-3 text-center">
                            <img src={img1} alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Conditions;