import React from 'react';
import leasline from '../../assets/img/leasline.png';
import imghigh from '../../assets/img/high.png';
import pointimg from '../../assets/img/point.jpg'
import img1 from '../../assets/img/banner_image_1.svg';
import bannerimg from '../../assets/img/main-imag.jpg';
import AboutUs from '../../Component/AboutUsPage/AboutUs';
import Footer from '../../Component/comman/Footer';
import Price from '../../Component/PricingPage/Price';
import RefundPolicy from '../../Component/RefundPolicyPage/RefundPolicy';
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
    render() {
        return (
            <div>
                <header style={{ minHeight: '100vh', padding: ' 0px 0 0 0' }}>
                    <div className="banner position-relative" style={{ overflow: "hidden",height: "calc(100vh - 78px)"}}>
                        <img className='main-img' src={bannerimg} alt="" />
                        <div className='banner-main text-center' >
                            <h1 className='banner-header text-left text-center'><span style={{color:'rgb(81 154 134)'}}>Quantasoul</span> <span style={{color:'gray'}}>Airologix</span></h1>
                            <p className=' fw-bold banner-detail'> Quantasoul Airologix Pvt Limited is authorized Internet a Category ‘B’ Internet Services Provider (ISP) and Category “A” Virtual Network Operator (VNO) licensed by the Department of Telecommunication, Government of India.</p>
                            <div className='text-center my-4 banner-btn-container'>
                                <Link to={process.env.PUBLIC_URL + "/about-us"} className="banner-btn btn btn-lg btn-primary mt-3 mx-2">Read More</Link>
                            </div>
                        </div>
                    </div>

                </header>
                <div className="page-section">
                    <div className="container-card ">
                        <div className="row">
                            <h1 className="subhead text-center mb-3 fw-bold">Services</h1>
                            <div className="col-lg-4 services ">

                                <div className="card-service wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp', backgroundColor: '#FFFFFF' }}>
                                    <div className="header">
                                        <img src={leasline} alt="" className='sevices-img' />
                                    </div>
                                    <div className="body">
                                        <h5 className="text-secondary">Dedicate Internet leas line Solution for Corporate</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-service wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp', backgroundColor: '#FFFFFF' }}>
                                    <div className="header">
                                        <img src={imghigh} alt="" className='sevices-img' />
                                    </div>
                                    <div className="body">
                                        <h5 className="text-secondary">High Speed Broad Band Solution</h5>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-service wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp', backgroundColor: '#FFFFFF' }}>
                                    <div className="header">
                                        <img src={pointimg} alt="" className='sevices-img' />
                                    </div>
                                    <div className="body">
                                        <h5 className="text-secondary">Point To Point Connectivity Solution For Corporate</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page-section' id='about'>
                    <AboutUs />
                </div>
                {/* <div className='page-section banner-info'>
                    <PrivacyPolicy />
                </div> */}
                {/* <div className='page-section' id='condition'>
                    <Conditions />
                </div> */}

                <div className='page-section'>
                    <Price />
                </div>
                {/* <div className='page-section banner-info'>
                    <RefundPolicy />
                </div> */}
                {/* <Footer /> */}
            </div>
        )
    }
}

export default Dashboard;