import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/quantasoul_logo.jpeg'

class Sidebar extends React.Component {
    closeSidebar() {
        var sidebar = document.getElementById('mainsidebar')
        if (sidebar) {
            if (sidebar.classList.contains('d-none')) {
                sidebar.classList.remove('d-none')
            } else {
                sidebar.classList.add('d-none')
            }
        }
    }
    render() {
        return (
            <div id='mainsidebar' className='sidebar col-2 d-none'>
                <div className='' >
                    {/* <div className='sidebar col-2 bg-success ' style={{ backgroundColor: '#898e9342', position: 'fixed', zIndex: 999 }}>
                 <div className=' mb-5' style={{ height: '100vh', borderRadius: '10px' }}> */}
                    <div className='m-2'>
                        <a href="#!" className="navbar-brand text-primary mt-2"><img src={logo} alt="" style={{ width: '200px' }} /></a>
                    </div>
                    <hr />
                    <div className='details mt-4 mb-3'>
                        <div className='row g-3 '>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/"}>Home</Link>
                            </div>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/about-us"}>About</Link>
                            </div>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>
                            </div>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/price"}>Pricing</Link>
                            </div>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/refund-policy"}>Refund Policy</Link>
                            </div>
                            <div>
                                <Link onClick={() => { this.closeSidebar() }} className="nav-link text-light fs-5" to={process.env.PUBLIC_URL + "/condition"}>Terms & Conditions</Link>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Link onClick={() => { this.closeSidebar() }} className="btn btn-primary text-light" to={process.env.PUBLIC_URL + "/contactus"}>Contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;