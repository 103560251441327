import React from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/quantasoul_logo.jpeg'


class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white sticky" data-offset="500" style={{ maxHeight: '722px' }}>
                <div className="w-100 d-flex justify-content-between align-items-center px-4">
                    <a href="#!" className="navbar-brand text-primary"><img src={logo} alt="" style={{width:'200px'}}/></a>
                    <div className="navbar-collapse collapse" id="navbarContent">
                        <ul className="navbar-nav ml-auto fs-5">
                            <li className="nav-item ">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/about-us"}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/price"}>Pricing</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/refund-policy"}>Refund Policy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/condition"}>Terms & Conditions</Link>
                            </li>
                           
                            <li className="nav-item ml-4">
                                <Link className="btn btn-primary ml-lg-2" to={process.env.PUBLIC_URL + "/contactus"}>Contact us</Link>
                            </li>
                            
                        </ul>
                    </div>
                    <button className="navbar-toggler"  data-toggle="collapse" data-target="#navbarContent" onClick={()=>{
                       var sidebar = document.getElementById('mainsidebar')
                            if (sidebar) {
                                if (sidebar.classList.contains('d-none')) {
                                    sidebar.classList.remove('d-none')
                                } else {
                                    sidebar.classList.add('d-none')
                                }
                            }
                    }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                </div>
            </nav>
        )
    }
}
export default Navbar;