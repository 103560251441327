import React from 'react';

class Contactpage extends React.Component {
    render() {
        const { username, mobilenum, onTostOpen, Onchangeusername, OnchangeMobileNumber, Onchangeemail, email, OnchangeAddress, address, detail, OnchangeDetail } = this.props
        return (
            <div className='section '>
                <div className=" contact-page d-flex justify-content-center">
                    <div className='d-flex justify-content-center  col-10'>
                        <div className='about-main row create-new-item p-3'>
                            <div className='col mb-3 mt-2'>
                                <h1 className='about-header mb-3 fw-bold' >ContactUs</h1>
                            </div>
                            <div className='mb-5 ' >
                                <label className='contact-label form-label fw-bold'>Name</label> <span className='text-danger'>*</span>
                                <input className='form-control' placeholder='name' value={username} onChange={Onchangeusername} />
                            </div>
                            <div className='mb-5 ' >
                                <label className='contact-label form-label fw-bold'>Mobile Number</label> <span className='text-danger'>*</span>
                                <input type="text" className='form-control ' placeholder='mobile number' value={mobilenum} onChange={OnchangeMobileNumber} />
                            </div>
                            <div className='mb-5 ' >
                                <label className='contact-label form-label fw-bold'>Email</label> <span className='text-danger'>*</span>
                                <input type="email" className='form-control' placeholder='email' value={email} onChange={Onchangeemail} />
                            </div>
                            <div className='mb-5 ' >
                                <label className='contact-label form-label fw-bold'>Address</label> <span className='text-danger'>*</span>
                                <input type="text" className='form-control' placeholder='Address' value={address} onChange={OnchangeAddress} />
                            </div>
                            <div className="mb-3 ">
                                <label className="contact-label form-label fw-bold">Details</label>
                                <textarea className="form-control fs-4" placeholder="Enter your details" rows='5' value={detail} onChange={OnchangeDetail}></textarea>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-primary m-2 fw-bold lift " onClick={onTostOpen} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex mb-3 col-12 border p-2 text-light' style={{ backgroundColor: 'gray' }}>
                    <div className='col-6 '>
                        <h5 className='fw-bold fs-2' >Address:</h5>
                        <p className='fs-4'>907,pinnacle Business Park,Corporate Road,Prahlad Nagar</p>
                    </div>
                    <div className='col-6 justify-content-right align-items-right'>
                        <h5 className='fw-bold fs-2'>Email:</h5>
                        <p className='fs-4'>samin@quantasoul.com<br />Mo-9825801301</p>

                    </div>

                </div> */}
            </div>
        )
    }
}

export default Contactpage;