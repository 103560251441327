import React from 'react';
import Price from '../../Component/PricingPage/Price';

class PricingPage extends React.Component{
    render(){
        return(
            <>
                <Price/>
            </>
        )
    }
}

export default PricingPage;