import React from 'react';
import AboutUs from '../../Component/AboutUsPage/AboutUs';

class About extends React.Component{
    render(){
        return(
            <>
                <AboutUs/>
               
            </>
        )
    }
}

export default About;