import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {connect} from 'react-redux';
import {onTostOpen} from './Redux/Actions/Actions'
import Navbar from './Component/comman/Navbar';
import About from './screen/AboutPage/About';
import ContactUs from './screen/ConatctUS/ContactUs';
import TermAndCondition from './screen/ConditionPage/TermAndCondition';
import Dashboard from './screen/Dashboard/Dashboard';
import PricingPage from './screen/PricingPage/PricingPage';
import Privacy from './screen/PrivacyPolicyPage/Privacy';
import Refund from './screen/RefundPolicyPage/Refund';
import img1 from './assets/img/Mobile-Tower-PNG-Free-Image.png'
import Footer from './Component/comman/Footer';
import Sidebar from './Component/comman/Sidebar';
import { Toast } from 'react-bootstrap';

class App extends React.Component {

  closeSidebar() {
    var sidebar = document.getElementById('mainsidebar')
    if (sidebar) {
      if (sidebar.classList.contains('d-none')) {
        return
      } else {
        sidebar.classList.add('d-none')
      }
    }
  }
  render() {
    const {tostmsg}=this.props.Mainreducer
    return (<>
      <div className="">
        <div className='backound-page'>
          <img className='bg-image' alt='' src={img1} />
        </div>
        <div className="back-to-top" style={{ visibility: 'hidden' }}></div>
        <Navbar />
        <div className="toast-block" id="toastBlock">
         <Toast show={tostmsg} className=" text-dark">
            <Toast.Body>
              <h5>this is tost message</h5>
            </Toast.Body>
          </Toast>
         
        </div>
        <Sidebar />
        <div onClick={() => { this.closeSidebar() }} style={{ zIndex: 99, background: "#ffffffeb", minHeight: '89vh' }}>
          <Switch>
            <Route exact path={"/"} render={() => { return <Dashboard /> }} />
            <Route exact path={"/about-us"} render={() => { return <About /> }} />
            <Route exact path={ "/privacy-policy"} render={() => { return <Privacy /> }} />
            <Route exact path={ "/condition"} render={() => { return <TermAndCondition /> }} />
            <Route exact path={ "/refund-policy"} render={() => { return <Refund /> }} />
            <Route exact path={ "/price"} render={() => { return <PricingPage /> }} />
            <Route exact path={ "/contactus"} render={() => { return <ContactUs /> }} />
          </Switch>
          <Footer />
        </div>
      </div>
    </>
    )
  }
}
const mapStateToProps=({Mainreducer})=>({
  Mainreducer
})

export default connect(mapStateToProps,{
  onTostOpen
}) (App);
