import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/img/banner_image_2.svg'

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className="wrap bg-image " style={{ backgroundImage: 'url(../assets/img/bg_pattern.svg)' }}>
                <div className="container p-5">
                    <div className="about-main row align-items-center text-center">
                        <h1 className="about-header  subhead fw-bold">Privacy Policy</h1>
                        
                        <div className="col-lg-12   wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                           
                            <p className="about-detail title-section">It is important for QAPL that you to know how we treat information about you that we may receive through this website <br /><Link to={"/"} className="text-decoration-none"><span className='text-warning'> www.quantasoul.com</span></Link></p>
                            <p className="about-detail">QAPL collects personal information when you register with us, when you use QAPL products or services, when you visit our web pages. QAPL may combine information about you that we have with information we obtain from business partners or other companies. QAPL will have the right to pass on the same to its business associates, franchisees without referring the same to you</p>
                            <div className="about-mini fw-bold mt-5" >When you register</div>
                            <p className="about-detail">we ask for information such as your name, email address, birth date, gender, zip code, occupation, industry, and personal interests. Once you register with QAPL and sign in to our services, you become our valued customer.</p>

                            <div className="about-mini fw-bold mt-5">QAPL collects information </div>
                            <p className="about-detail">QAPL collects information about your transactions with us and with some of our business partners, including information about your use of products and services that we offer</p>
                            <p className="about-detail">We are not bound to reply to you when you leave your personal details.</p>

                            <div className="about-mini fw-bold mt-5" >We make use of User Information for the following</div>
                            <p className="about-detail">we ask for information such as your name, email address, birth date, gender, zip code, occupation, industry, and personal interests. Once you register with QAPL and sign in to our services, you become our valued customer.</p>
                            <p className="about-detail">Market research, including statistical analysis of user behaviour which we may disclose to third parties in aggregated form</p>
                            <p className="about-detail">In compliance with any requirement of law.</p>
                            <p className="about-detail">To send you periodic communications about our features, products, services, events and special offers</p>


                            <div className='about-mini fw-bold mt-5' >We do not share User Information with third parties.</div>
                            <p className="about-detail">Security is very important to us. All security procedures are in place to protect the confidentiality, integrity and availability of your User Information. We maintain strict physical, electronic, and administrative safeguards to protect Your personal information from unauthorized or inappropriate access. We restrict access to information about you to those of our employees who need to know the information to respond to your inquiry or request. However, as no computer or network-based product exists that can provide perfect security, QAPL can’t guarantee Your information as completely risk free.</p>
                            <p className="about-detail">QAPL may update this policy and display the same in their website without notice to you and such posting will be deemed to have been read by you.</p>
                            <p className="about-detail">We may change this privacy policy from time to time and you should check these regularly. Your use of Quantasoul Airologix website will be deemed an acceptance of the privacy policy existing at that time.</p>

                        </div>
                        {/* <div className="col-lg-6 py-3 wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                            <div className="img-fluid text-center">
                                <img src={img1} alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


        )
    }
}

export default PrivacyPolicy;