import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/quantasoul_logo.jpeg'

class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer bg-image" style={{ backgroundImage: 'url(../assets/img/world_pattern.svg)' }}>
                <div className="container">
                    <div className="footer-contain row">
                        <div className="col-lg-4 ">
                            <a href="#!" className="navbar-brand text-primary"><img src={logo} alt="" style={{ width: '250px' }} /></a>
                            {/* <div className="social-media-button">
                                <a href="#!"><span className="mai-logo-facebook-f"></span></a>
                                <a href="#!"><span className="mai-logo-twitter"></span></a>
                                <a href="#!"><span className="mai-logo-google-plus-g"></span></a>
                                <a href="#!"><span className="mai-logo-instagram"></span></a>
                                <a href="#!"><span className="mai-logo-youtube"></span></a>
                            </div> */}
                        </div>
                        <div className="col-lg-4">
                            {/* <h5>Company</h5> */}
                            <ul className="footer-menu ">
                                {/* <li className='fs-4'><Link className='text-secondary' to="/about-us">About Us</Link></li> */}
                                <li className='fs-4'><Link className='text-secondary' to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                                <li className='fs-4'><Link className='text-secondary' to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                <li className='fs-4'><Link className='text-secondary' to={process.env.PUBLIC_URL + "/price"}>Pricing</Link></li>
                                <li className='fs-4'><Link className='text-secondary' to={process.env.PUBLIC_URL + "/refund-policy"}>Refund Policy</Link></li>
                                <li className='fs-4'><Link className='text-secondary' to={process.env.PUBLIC_URL + "/condition"}>Terms & Conditions</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 ">
                            <Link to="" className="text-secondary text-decoration-none fs-4">Contact Us</Link><br />
                            <Link to="" className=" text-secondary text-decoration-none fs-6">907,pinnacle Business Park,Corporate Road,Prahlad Nagar</Link><br />
                            <Link to="" className=" text-secondary text-decoration-none fs-6">9825801301</Link><br />
                            <Link to="" className="text-secondary text-decoration-none fs-6">samin@quantasoul.com</Link>
                        </div>

                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;