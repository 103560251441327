export const Onchangeusername = (e) => (dispatch) => {
    dispatch({
        type: 'USER_NAME',
        payload: e
    })
}

export const OnchangeMobileNumber = (e) => (dispatch) => {
    dispatch({
        type: 'MOBILE_NUMBER',
        payload: e
    })
}

export const Onchangeemail = (e) => (dispatch) => {
    dispatch({
        type: 'EMAIL',
        payload: e
    })
}

export const OnchangeAddress = (e) => (dispatch) => {
    dispatch({
        type: 'ADDRESS',
        payload: e
    })
}
export const OnchangeDetail = (e) => (dispatch) => {
    dispatch({
        type: 'DETAIL',
        payload: e
    })
}

export const onTostOpen = (val) => (dispatch) => {
    dispatch({
        type: 'TOST_MESSAGE',
        payload: val
    })
    setTimeout(() => {
        dispatch({
            type: "TOST_MESSAGE",
            payload: false,
        });
    }, 5000)
}

