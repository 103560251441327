import React from 'react';
import img1 from '../../assets/img/banner_image_2.svg'

class RefundPolicy extends React.Component {
    render() {
        return (<div className="wrap bg-image" style={{ backgroundImage: 'url(../assets/img/bg_pattern.svg)' }}>
            <div className="container p-5 text-center">
                <div className="row align-items-center">
                    <div className="about-main col-lg-12 wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        <h2 className="about-header title-section fw-bold mb-3">CANCELLATION & REFUND POLICY </h2>
                        {/* <div className="divider"></div> */}
                        <p className='about-detail'>QAPL believes in helping its customers as far as possible and has therefore a liberal cancellation policy. </p>
                        <p className='about-detail'>Cancellations will be considered only if the request is made within 48 hours of placing an order. The Installation charge is not refundable. </p>
                        <p className='about-detail'>No amount will be refunded after activation of Services as our services are prepaid.</p>
                        <p className='about-detail'>Refunds will only be made in 5-10 working days after approval and, tax will not be refunded. Refunds will only be made by direct deposit (Electronic Fund Transfer) into your bank account.</p>
                    </div>
                    {/* <div className="col-lg-6 py-3 wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                            <div className="img-fluid text-center">
                                <img src={img1} alt="" />
                            </div>
                        </div> */}
                </div>
            </div>
        </div>


        )
    }
}

export default RefundPolicy;