import React from 'react';
import { connect } from 'react-redux';
import { onTostOpen, Onchangeusername, OnchangeMobileNumber, OnchangeAddress, Onchangeemail, OnchangeDetail } from '../../Redux/Actions/Actions'
import Contactpage from '../../Component/ContactUs/Contactpage';

class ContactUs extends React.Component {
    render() {
        const { username, mobilenum, email, address, detail } = this.props.Mainreducer
        return (
            <div className='section'>
                <Contactpage
                    onTostOpen={(val) => { this.props.onTostOpen(true) }}
                    username={username}
                    mobilenum={mobilenum}
                    email={email}
                    address={address}
                    detail={detail}
                    Onchangeemail={(e) => { this.props.Onchangeemail(e.target.value) }}
                    Onchangeusername={(e) => { this.props.Onchangeusername(e.target.value) }}
                    OnchangeMobileNumber={(e) => { this.props.OnchangeMobileNumber(e.target.value) }}
                    OnchangeAddress={(e) => { this.props.OnchangeAddress(e.target.value) }}
                    OnchangeDetail={(e) => { this.props.OnchangeDetail(e.target.value) }}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
})

export default connect(mapStateToProps, {
    onTostOpen,
    Onchangeusername,
    OnchangeMobileNumber,
    OnchangeAddress,
    Onchangeemail,
    OnchangeDetail
})(ContactUs);