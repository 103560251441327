import React from 'react';
import img1 from '../../assets/img/about_frame.png'

class AboutUs extends React.Component {
    render() {
        return (
            <div className="container " >
                <div className="row align-items-center text-center">
                    <div className="about-main col-lg-12  wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        <h1 className="about-header subhead fw-bold ">About us</h1>
                        {/* <div className="divider"></div> */}
                        {/* <h4 className='text-left fw-bold'>Quantasoul Airologix</h4>
                        <p> Pvt Limited is authorized Internet a Category ‘B’ Internet Services Provider (ISP) and Category “A” Virtual Network Operator (VNO) licensed by the Department of Telecommunication, Government of India.</p> */}

                        {/* <div className='text-right my-4'>
                            <a href="/about-us" className=" btn btn-primary mt-3 ">Read More</a>
                        </div> */}

                        <p className='about-detail '>Innovation is QAPL’s driving force and that’s why the company aims to create an environment for the young talent to conceive and incubate fresh ideas to develop and deliver useful services & solutions, thereby sustaining competitive advantages. We provide internet services in All over Gujarat. We believe in making long term relationship with customer.</p>
                        <p className='about-detail'>QAPL’s vision is to offer next generation services to their customers and become one of the leading ISPs in Gujarat. Our aim is expanding Our Network, to neighbouring States of Gujarat by offering innovative products and providing High speed Internet Service</p>
                        <p className='about-detail'>QAPL offers what every user should expect from their Internet experience - High-quality connectivity, minimal drop-offs and ISP-generated intrusions, and customizable features</p>
                        <p className='about-detail '>We place a high premium on our people, quality and business values. We develop our employees through continuous training and provide them with opportunities to work on state-of-art technologies</p>
                        <p className='about-detail '>We understand the heartbeat of today's modern work environments where employees may work in the office, at home, on the move, or in any convenient location. We ensure that our customers stay connected wherever they are, whatever device they use.</p>
                        <p className='about-detail'>We develop and deliver exceptional and innovative communication solutions built on the strong and secure foundation of cloud technologies</p>
                        <p className='about-detail '>QAPL is most preferred choice among Govt./ Semi Govt./ PSU and Corporate for their IT and Security Solutions because of its strong technical expertise, ability to provide solution on PAN India basis. QAPL has done rigorous R&D in regards to the product development and market study for providing quality solutions to the clients.</p>

                    </div>
                    {/* <div className="col-lg-6 py-3 wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                        <div className="img-fluid py-3 text-center">
                            <img src={img1} alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default AboutUs;