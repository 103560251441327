import React from 'react'

class Price extends React.Component {
  render() {
    return (<div className="container-price ">
      <div className="text-center wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
        <div className="subhead">Pricing Plan</div>
        <h2 className="title-section">Choose plan the right for you</h2>
        <div className="divider mx-auto"></div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-4 py-3 wow zoomIn" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
          <div className="card-pricing">
            <div className="header">
              <div className="pricing-type">UNLIMITED PLANS FOR</div>
              <div className="price">

                <h1>6<span className="suffix"></span></h1>
              </div>
              <h5> Month</h5>
            </div>
            <hr />
            <div className=' d-flex justify-content-between p-2'>
              <h5 className=''>Speed</h5>
              <h5 className=''>Rupees</h5>
            </div>

            <div className=' d-flex justify-content-between p-2'>
              <span>20 Mbps</span>
              <span className=''><i className="fa fa-indian-rupee-sign m-1"></i>4699.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>50 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>4899.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>60 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>4999.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>80 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>5299.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>100 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>5899.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className='' style={{ marginTop: '16px', opacity: '0.5' }}>
              <span className='text-danger'>* Installation Charge Will be Extra</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 py-3 wow zoomIn" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
          <div className="card-pricing ">
            <div className="header">
              <div className="pricing-type">UNLIMITED PLANS FOR </div>
              <div className="price">
                <h1>9<span className="suffix"></span></h1>
              </div>
              <h5> Month</h5>
            </div>
            <hr />
            <div className=' d-flex justify-content-between p-2'>
              <h5 className=''>Speed</h5>
              <h5 className=''>Rupees</h5>
            </div>

            <div className=' d-flex justify-content-between p-2'>
              <span>20 Mbps</span>
              <span className=''><i className="fa fa-indian-rupee-sign m-1"></i>5875.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>50 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>6125.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>60 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>6250.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>80 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>6625.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>100 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>7375.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className='' style={{ marginTop: '16px', opacity: '0.5' }}>
              <span className='text-danger'>* Installation Charge Will be Extra</span>
            </div>
          </div>
        </div>

        <div className="col-lg-4 py-3 wow zoomIn" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
          <div className="card-pricing">
            <div className="header">
              <div className="pricing-type">UNLIMITED PLANS FOR </div>
              <div className="price">
                <h1>12<span className="suffix"></span></h1>
              </div>
              <h5>Month</h5>
            </div>
            <hr />
            <div className=' d-flex justify-content-between p-2'>
              <h5 className=''>Speed</h5>
              <h5 className=''>Rupees</h5>
            </div>

            <div className=' d-flex justify-content-between p-2'>
              <span>20 Mbps</span>
              <span className=''><i className="fa fa-indian-rupee-sign m-1"></i>7350.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>50 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>7675.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>60 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>7825.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>80 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>8300.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className=' d-flex justify-content-between p-2'>
              <span>100 Mbps</span>
              <span><i className="fa fa-indian-rupee-sign m-1"></i>9225.00<span className='text-danger' style={{ opacity: '0.5' }}>*</span></span>
            </div>
            <div className='' style={{ marginTop: '16px', opacity: '0.5' }}>
              <span className='text-danger'>* Installation Charge Will be Extra</span>
            </div>
          </div>
        </div>

      </div>
    </div>


    )
  }
}

export default Price;