import React from 'react';
import Conditions from '../../Component/ConditionsPage/Conditions';


class TermAndCondition extends React.Component{
    render(){
        return(
            <>
                <Conditions/>
            </>
        )
    }
}

export default TermAndCondition;