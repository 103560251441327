import React from 'react';
import PrivacyPolicy from '../../Component/PrivacyPolicyPage/PrivacyPolicy';

class Privacy extends React.Component{
    render(){
        return(
            <>
                <PrivacyPolicy/>
            </>
        )
    }
}

export default Privacy;