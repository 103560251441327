const initialState = {
    username: '',
    tostmsg: false,
    mobilenum: '',
    email:'',
    address:'',
    detail:''
}

const Mainreducer = (state = initialState, action) => {
    switch (action.type) {

        case 'USER_NAME': {
            return {
                ...state,
                username: action.payload
            }
        }
        case "MOBILE_NUMBER": {        
            return {
                ...state,
                mobilenum: action.payload
            }
        }
        case "EMAIL": {
            return {
                ...state,
                email: action.payload
            }
        }
        case "ADDRESS": {
            return {
                ...state,
                address: action.payload
            }
        }
        case "DETAIL": {
            console.log(action.payload);
            return {
                ...state,
                detail: action.payload
            }
        }
        case 'TOST_MESSAGE': {
            return {
                ...state,
                tostmsg: action.payload
            }
        }

        default: {
            return state
        }
    }
}

export default Mainreducer;