import React from 'react';
import RefundPolicy from '../../Component/RefundPolicyPage/RefundPolicy';

class Refund extends React.Component{
    render(){
        return(
            <>
                <RefundPolicy/>
            </>
        )
    }
}

export default Refund;